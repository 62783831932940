import { Button, Box, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const SignUpButton = styled(Button)`
  width: 100%;
  margin-top: 25px;
`;

export function PAYGCard() {
  return (
    <Link to="/sign-up">
      <SignUpButton variant="contained" color="success">
        Sign up
      </SignUpButton>
    </Link>
  );
}

export function CustomCard() {
  return (
    <Box marginTop={2} marginBottom={6}>
      <Typography variant="h5">Something else</Typography>
      <Typography variant="body2">
        Not sure if pay-as-you-go is right for you? Need a plan specific to your
        organisation's needs?{" "}
        <a href="mailto:jordan@solvality.com" rel="nofollow">
          Contact us
        </a>
      </Typography>
    </Box>
  );
}
