import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { PRODUCT_NAME } from "../Globals";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useContext } from "react";
import { MobileContext } from "../App";

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ContainerWrapper = styled(TableContainer)`
  padding-bottom: 50px;
`;

function NavBar() {
  const isMobile = useContext(MobileContext);

  return (
    <ContainerWrapper>
      {isMobile ? (
        <>
          <Typography marginTop={2} align="center" variant="h5">
            <StyledLink to="/">{PRODUCT_NAME}</StyledLink>
          </Typography>
          <Typography align="center" variant="body2">
            Your new eye in the sky
          </Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <StyledLink to="https://blog.bugscan.com.au">Blog</StyledLink>
                </TableCell>
                <TableCell>
                  <StyledLink to="/terms-of-service">
                    Terms of Service
                  </StyledLink>
                </TableCell>
                <TableCell>
                  <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </>
      ) : (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <StyledLink to="/">{PRODUCT_NAME}</StyledLink>
              </TableCell>
              <TableCell>
                <Typography variant="body2">Your new eye in the sky</Typography>
              </TableCell>
              <TableCell>
                <StyledLink to="https://blog.bugscan.com.au">Blog</StyledLink>
              </TableCell>
              <TableCell>
                <StyledLink to="/terms-of-service">Terms of Service</StyledLink>
              </TableCell>
              <TableCell>
                <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </ContainerWrapper>
  );
}

export default NavBar;
