import { CircularProgress, Container } from "@mui/material";
import { FeatureCards } from "./components/FeatureCards";
import NavBar from "./components/NavBar";
import { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { CustomCard, PAYGCard } from "./components/PricingCards";
import { Authenticator } from "@aws-amplify/ui-react";
import React from "react";
import Page from "./components/Page";

export const MobileContext = React.createContext(false);

const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicyComponent"));
const TermsOfService = lazy(() => import("./Policies"));
const Return = lazy(() => import("./components/stripe/StripeComponent"));
const CheckoutForm = lazy(
  () => import("./components/stripe/CheckoutComponent")
);
const SignUpForm = lazy(() => import("./SignUp"));
const Manage = lazy(() => import("./Manage"));

const GetRoutes = () => (
  <Suspense fallback={<CircularProgress />}>
    <Routes>
      <Route
        path="/"
        element={
          <Page title="BugScan - Home">
            <Home />
          </Page>
        }
      />
      <Route
        path="/sign-up"
        element={
          <Page title="BugScan - Sign Up">
            <SignUpForm />
          </Page>
        }
      />
      <Route
        path="/check-out"
        element={
          <Page title="BugScan - Checkout">
            <CheckoutForm />
          </Page>
        }
      />
      <Route
        path="/return"
        element={
          <Page title="BugScan - Checkout">
            <Return />
          </Page>
        }
      />
      <Route
        path="/manage"
        element={
          <Page title="BugScan - Manage">
            <Manage />
          </Page>
        }
      />
      <Route
        path="/terms-of-service"
        element={
          <Page title="BugScan - Terms of Service">
            <TermsOfService />
          </Page>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Page title="BugScan - Privacy Policy">
            <PrivacyPolicy />
          </Page>
        }
      />
    </Routes>
  </Suspense>
);

const id = "G-DRKXT0FLVF";

const gtagScript = `window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "${id}");`;

function App() {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    setIsMobile(window.screen.width < 850);
  };

  useEffect(() => {
    setIsMobile(window.screen.width < 850);
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    script.async = true;
    document.body.appendChild(script);

    const secondScript = document.createElement("script");
    secondScript.text = gtagScript;
    document.body.appendChild(secondScript);
  }, []);

  return (
    <>
      <Router>
        <Authenticator.Provider>
          <MobileContext.Provider value={isMobile}>
            <NavBar />
            {isMobile ? (
              <div style={{ padding: "1rem" }}>
                <GetRoutes />
              </div>
            ) : (
              <Container>
                <GetRoutes />
              </Container>
            )}
          </MobileContext.Provider>
        </Authenticator.Provider>
      </Router>
    </>
  );
}

function Home() {
  const isMobile = useContext(MobileContext);

  return !isMobile ? (
    <>
      <div>
        <FeatureCards />
      </div>
      <PAYGCard />
      <CustomCard />
    </>
  ) : (
    <>
      <FeatureCards />
      <PAYGCard />
      <CustomCard />
    </>
  );
}

export default App;
