import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import scans from "../images/scans.webp";
import reliability from "../images/reliability.webp";
import support from "../images/support.webp";
import { useContext } from "react";
import { MobileContext } from "../App";

const Cards = [
  {
    title: "Regular scans",
    description: `We regularly scan your website for errors, anything detected is
          immediately alerted.
          Can't access or load your site? We'll let you know.
          You control how often your website is scanned, anywhere from hourly to
          once per month.`,
    mobileWidth: "30rem",
    imageSrc: scans,
  },
  {
    title: "Total reliability",
    description: `Focus on building your website, not maintaining it.
          
          We handle the work of setting up regular scans so you don't have to.`,
    mobileWidth: "30rem",
    imageSrc: reliability,
  },
  {
    title: "Exceptional support",
    description: `With new features being added constantly, expect regular, ongoing
          support.
          
          Want to change something? We can adjust your scan frequency and plan
          as needed.`,
    mobileWidth: "30rem",
    imageSrc: support,
  },
];

export function FeatureCards() {
  const isMobile = useContext(MobileContext);

  return isMobile ? (
    Cards.map((x, i) => (
      <div key={i} style={{ maxWidth: x.mobileWidth, display: "inline" }}>
        <Typography variant={"h5"}>{x.title}</Typography>
        <CardContent>
          <img
            src={x.imageSrc}
            loading="lazy"
            style={{
              height: "200px",
              width: "100%",
              marginBottom: "50px",
            }}
          />
          <p>{x.description}</p>
        </CardContent>
      </div>
    ))
  ) : (
    <Table>
      <TableBody>
        <TableRow>
          {Cards.map((x, i) => (
            <TableCell>
              <Card key={i} style={{ maxWidth: "25rem", height: "490px" }}>
                <CardHeader title={x.title} />
                <CardContent>
                  <img
                    src={x.imageSrc}
                    loading="lazy"
                    style={{
                      height: "200px",
                      width: "100%",
                      marginBottom: "50px",
                    }}
                  />
                  <p>{x.description}</p>
                </CardContent>
              </Card>
            </TableCell>
          ))}
        </TableRow>
      </TableBody>
    </Table>
  );
}
