import ReactDOM from "react-dom/client";
import "./index.css";
import "@aws-amplify/ui-react/styles.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import App from "./App";
import { Amplify, type ResourcesConfig } from "aws-amplify";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const authConfig: ResourcesConfig["Auth"] = {
  Cognito: {
    userPoolId: "us-east-1_y3z8EUT20",
    userPoolClientId: "4sal1uo0lgupskp7emtfafurju",
  },
};

Amplify.configure({
  Auth: authConfig,
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage());

root.render(<App />);
