export const PRODUCT_NAME = "BugScan";

// Stripe
export const STRIPE_PUBLIC_KEY = "pk_live_51IwLVJEkBCqRfVR0vuOf4s2v6zJIkkX0HLuLpZdYMSop4eNzrUEInDESSF6HkTWLr1haR25nFvOt3Aav0VUjoZsi00F2kVhMJy";

export const API_GATEWAY = "https://api.bugscan.com.au";
export const STRIPE_CHECKOUT_API = `${API_GATEWAY}/paygCheckout`;
export const SIGN_UP_API = `${API_GATEWAY}/onboard`;
export const HEX_CODE = `#00ACBF`;

export enum RunInterval {
    "hourly" = 1,
    "daily" = 24,
    "weekly" = 168,
    "fortnightly" = 336,
    "monthly" = 730
}

export enum PricingPlan {
  "payg",
  "custom"
}

export interface OnboardData {
  domain: string;
  plan: PricingPlan;
  frequency: RunInterval
}
